import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import { getEntriesByTags } from "@/shared/contentful-fetches.mjs";

export default async function fetchData() {
  await getEntriesByTags(
    [
      "map",
      CONTENTFUL_TAGS[GAME_SHORT_NAMES[GAME_SYMBOL_MARVEL_RIVALS]],
    ].filter(Boolean),
  );
}
